import { DocumentCopyOfContractualElementDetails } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentCopyOfContractualElementInitialState = {
    results: {} as { [id: string]: ApiRequest<DocumentCopyOfContractualElementDetails> },
} as const;

type DocumentCopyOfContractualElementState = typeof documentCopyOfContractualElementInitialState;

export const DocumentCopyOfContractualElementReducer: Reducer<DocumentCopyOfContractualElementState> = (state = documentCopyOfContractualElementInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/COPY_OF_CONTRACTUAL_ELEMENT/GET':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/COPY_OF_CONTRACTUAL_ELEMENT/GET_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/COPY_OF_CONTRACTUAL_ELEMENT/GET_FAILURE':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;

    }
};