import { DocumentFormType } from 'components/Pages/CustomerService/Modal/FormType';

export enum CustomerServiceModalStep {
    CategorySelection,
    Form,
    Summary,
    Final,
    FinalError,
}

export type CheckBoxesDocuments = 
    DocumentFormType.ContractRelated |
    DocumentFormType.EquipmentRelated |
    DocumentFormType.ContractualCopy