import { CustomerRequestDetailsAction } from './CustomerRequestDetailsActions';
import { combineReducers } from 'store/storeHelper';
import { billingBankDetailInitialState, BillingBankDetailReducer } from './Billing/BankDetail/BankDetailReducer';
import { billingAddressInitialState, BillingAddressReducer } from './Billing/BillingAddress/BillingAddressReducer';
import { billingOrderNumberInitialState, BillingOrderNumberReducer } from './Billing/OrderNumber/OrderNumberReducer';

import { changeFinancialConditionInitialState, ChangeFinancialConditionReducer } from './Change/FinancialCondition/FinancialConditionReducer';
import { changeHeadOfficeAddressInitialState, ChangeHeadOfficeAddressReducer } from './Change/HeadOfficeAddress/HeadOfficeAddressReducer';

import { documentAccountExtractInitialState, DocumentAccountExtractReducer } from './Document/AccountExtract/AccountExtractReducer';
import { documentContractRelatedInitialState, DocumentContractRelatedReducer } from './Document/ContractRelated/ContractRelatedReducer';
import { documentCopyOfContractualElementInitialState, DocumentCopyOfContractualElementReducer } from './Document/CopyOfContractualElement/CopyOfContractualElementReducer';
import { documentEquipmentRelatedInitialState, DocumentEquipmentRelatedReducer } from './Document/EquipmentRelated/EquipmentRelatedReducer';

import { endOfContractReturnOfEquipmentInitialState, EndOfContractReturnOfEquipmentReducer } from './EndOfContract/ReturnOfEquipment/ReturnOfEquipmentReducer';
import { endOfContractEarlyRedemptionInitialState, EndOfContractEarlyRedemptionReducer } from './EndOfContract/EarlyRedemption/EarlyredemptionReducer';
import { endOfContractExerciseOfPurchaseOptionInitialState, EndOfContractExerciseOfPurchaseOptionReducer } from './EndOfContract/ExerciseOfPurchaseOption/ExerciseOfPurchaseOptionReducer';
import { endOfContractDenialOfContractRenewalInitialState, EndOfContractDenialOfContractRenewalReducer } from './EndOfContract/DenialOfContractRenewal/DenialOfContractRenewalReducer';
import { endOfContractTerminationOfContractInitialState, EndOfContractTerminationOfContractReducer } from './EndOfContract/TerminationOfContract/TerminationOfContractReducer';

import { mixedContractTransferInitialState, MixedContractTransferReducer } from './Mixed/ContractTransfer/ContractTransferReducer';

const formsInitialState = {
    billingBankDetail: billingBankDetailInitialState,
    billingOrderNumber: billingOrderNumberInitialState,
    billingAddress: billingAddressInitialState,
    changeHeadOfficeAddress: changeHeadOfficeAddressInitialState,
    changeFinancialCondition: changeFinancialConditionInitialState,
    documentContractRelated: documentContractRelatedInitialState,
    documentCopyOfContractualElement: documentCopyOfContractualElementInitialState,
    documentEquipmentRelated: documentEquipmentRelatedInitialState,
    documentAccountExtract: documentAccountExtractInitialState,
    endOfContractReturnOfEquipment: endOfContractReturnOfEquipmentInitialState,
    endOfContractEarlyRedemption: endOfContractEarlyRedemptionInitialState,
    endOfContractExerciseOfPurchaseOption: endOfContractExerciseOfPurchaseOptionInitialState,
    endOfContractDenialOfContractRenewal: endOfContractDenialOfContractRenewalInitialState,
    endOfContractTerminationOfContract: endOfContractTerminationOfContractInitialState,
    mixedContractTransfer: mixedContractTransferInitialState,
};

type FormsState = typeof formsInitialState;

export const initialState = {
    forms: formsInitialState,
} as const;

export type CustomerRequestDetailsState = typeof initialState;

const FormsReducers = combineReducers<FormsState, CustomerRequestDetailsAction>({
    billingBankDetail: BillingBankDetailReducer,
    billingOrderNumber: BillingOrderNumberReducer,
    billingAddress: BillingAddressReducer,
    changeHeadOfficeAddress: ChangeHeadOfficeAddressReducer,
    changeFinancialCondition: ChangeFinancialConditionReducer,
    documentContractRelated: DocumentContractRelatedReducer,
    documentCopyOfContractualElement: DocumentCopyOfContractualElementReducer,
    documentEquipmentRelated: DocumentEquipmentRelatedReducer,
    documentAccountExtract: DocumentAccountExtractReducer,
    endOfContractReturnOfEquipment: EndOfContractReturnOfEquipmentReducer,
    endOfContractEarlyRedemption: EndOfContractEarlyRedemptionReducer,
    endOfContractExerciseOfPurchaseOption: EndOfContractExerciseOfPurchaseOptionReducer,
    endOfContractDenialOfContractRenewal: EndOfContractDenialOfContractRenewalReducer,
    endOfContractTerminationOfContract: EndOfContractTerminationOfContractReducer,
    mixedContractTransfer: MixedContractTransferReducer,
});

export const CustomerRequestDetailsReducer = combineReducers<CustomerRequestDetailsState, CustomerRequestDetailsAction>({
    forms: FormsReducers,
});