import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import classNames from 'classnames';
import * as React from 'react';
import { t } from 'i18next';
import { PagerLinkType, generatePaginationLinks } from './PaginationLinks';

export interface IProps {
    skip: number;
    take: number;
    totalItems: number;
    onPageChange?: (skip: number, take: number) => void;

    showPrevLabel?: boolean;
    showNextLabel?: boolean;
    nbSiblings?: number;
}

export const Pager: React.FC<IProps> = ({ skip, take, totalItems, showPrevLabel = true, showNextLabel = true, nbSiblings = 1, onPageChange }) => {

    const getCurrentPage = () => Math.floor((skip - 1) / take) + 1;

    const getPageCount = () => Math.floor((totalItems - 1) / take) + 1;

    const handlePageChange = (skip: number, take: number): void => {
        if (onPageChange) {
            onPageChange(skip, take);
        }
    };

    const currentPage = getCurrentPage();
    const pageCount = getPageCount();

    return <PagerLinks
        currentPage={currentPage + 1}
        totalPages={pageCount}
        showNextLabel={showNextLabel}
        showPrevLabel={showPrevLabel}
        nbSiblings={nbSiblings}
        onPageChanged={(page) => handlePageChange((page - 1) * take, take)} />;
};

const PagerLinks: React.FC<{
    currentPage: number,
    totalPages: number,
    nbSiblings: number,
    showPrevLabel?: boolean,
    showNextLabel?: boolean,
    onPageChanged: (currentPage: number) => void;
}>
    = ({ currentPage, totalPages, nbSiblings, showPrevLabel, showNextLabel, onPageChanged }) => {

        const getLink = (page: number, type: PagerLinkType) => {
            switch (type) {
                case '<':
                    return <button
                        disabled={currentPage <= 1}
                        className="btn btn-flat-primary  btn-md btn-icon-text"
                        onClick={() => onPageChanged(page)}>
                        <KeyboardArrowLeftIcon className="icon" fontSize="inherit" />
                        {showPrevLabel && <span className=" me-2">{t('common:Pager.Prev')}</span>}
                    </button>;
                case '>':
                    return <button
                        disabled={currentPage >= totalPages}
                        className="btn btn-flat-primary btn-md btn-text-icon"
                        onClick={() => onPageChanged(page)}>
                        {showNextLabel && <span className=" ms-2">{t('common:Pager.Next')}</span>}
                        <KeyboardArrowRightIcon className="icon" fontSize="inherit" />
                    </button>;

                case '...':
                    return <button
                        type="button"
                        className="btn btn-flat-primary btn-md"
                        onClick={() => onPageChanged(page)}>
                        ...
                    </button>;
                case 'currentPage':
                case 'siblingPage':
                case 'first':
                case 'last':
                default:
                    return <button
                        type="button"
                        className={classNames('btn btn-flat-primary', { 'active': page === currentPage })}
                        onClick={() => onPageChanged(page)}>
                        {page}
                    </button>;
            }
        };

        return <ul className="pagination justify-content-center justify-content-md-end">
            {generatePaginationLinks(currentPage, totalPages, nbSiblings).map(({ page, type }) => {
                return <li key={`${page}_${type}`}>{getLink(page, type)}</li>;
            })}
        </ul>;

    };