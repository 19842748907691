import { ThunkDispatch, useAppContext } from 'components/common/AppProvider';
import { Pager } from 'components/common/Pager';
import { QuickSearch } from 'components/common/QuickSearch';
import { debounce } from 'lodash-es';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody } from 'reactstrap';
import { setNavigateAs } from 'services/NavigateAsService';
import { createCloseModal } from 'store/Modals/ModalsActions';
import { FranfinanceSelectors } from 'store/Normalizr/FranfinanceSelectors';
import { createSetNavigateAsPersonsSearchTerms } from 'store/Persons/PersonsActions';
import { searchNavigateAs } from 'store/Persons/PersonsThunk';

export const NavigateAsModalName = 'NavigateAsModal';

export const NavigateAsModal: React.FC = () => {
    const { t } = useTranslation();
    const { dispatch, state } = useAppContext();
    const isOpen = state.modals[NavigateAsModalName];
    const persons = FranfinanceSelectors.getPersonArray(state.persons.navigateAs.data, state.entities.franFinance);
    const [skip, setSkip] = useState(0);
    const take = 5;

    const handleCancel = () => {
        dispatch(createCloseModal(NavigateAsModalName));
    };

    const handleSearch = (newSearchTerms?: string) => {
        dispatch(createSetNavigateAsPersonsSearchTerms(newSearchTerms));
        debouncedSearchNavigateAs(dispatch);
        setSkip(0);
    };

    const handlePageChange = (skip: number) => {
        setSkip(skip);
    };

    const handleNavigateAs = (icId: string) => {
        setNavigateAs(icId);
        dispatch(createCloseModal(NavigateAsModalName));
    };

    return <Modal isOpen={isOpen} toggle={handleCancel} className="modal-lg">
        <div className="modal-header">
            <h3 className="modal-title">
                {t('common:NavigateAsModal.Navigate as')}
            </h3>
            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleCancel} />
        </div>
        <ModalBody>
            <QuickSearch
                searchTerms={state.persons.navigateAs.searchTerms}
                onSearch={handleSearch}
                placeholder={t('common:NavigateAsModal.Search by email lastname firstname')}
            />
            <ul className="list-group gap-y-2 d-flex py-2">
                <li className="d-flex list-group-item p-0">
                    <div className="d-flex justify-content-between flex-fill px-3 py-2">
                        <span className="text-secondary" style={{ flexBasis: '50%' }}>{t('common:NavigateAsModal.Client name')}</span>
                        <span className="text-secondary" style={{ flexBasis: '50%' }}>{t('common:NavigateAsModal.Client company')}</span>
                    </div>
                </li>
                {persons?.slice(skip, skip + take).map(p => {
                    return <li
                        key={p.icId}
                        className="d-flex list-group-item list-group-item-action p-0"
                    >
                        <button
                            type="button"
                            className="btn btn-flat d-flex justify-content-between flex-fill px-3 py-2"
                            onClick={() => handleNavigateAs(p.icId)}
                        >
                            <span style={{ flexBasis: '50%' }} className="text-start">{p.lastName} {p.firstName}</span>
                            <span style={{ flexBasis: '50%' }} className="text-start">{p.clientName}</span>
                        </button>
                    </li>;
                })}
            </ul>
            <Pager skip={skip} take={take} totalItems={persons.length} showNextLabel onPageChange={handlePageChange} />
        </ModalBody>
    </Modal>;
};

const debouncedSearchNavigateAs = debounce((dispatch: ThunkDispatch) => {
    dispatch(searchNavigateAs()).catch(() => void 0);
}, 300);
