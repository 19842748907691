import { TypeOfBuyback } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractEarlyRedemptionInitialState = {
    contractIds: [] as string[],
    typeofbuyback: undefined as TypeOfBuyback | undefined,
    materials: undefined as string[] | undefined,
    redemptionDate: undefined as Date | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type EndOfContractEarlyRedemptionState = typeof endOfContractEarlyRedemptionInitialState;
export const sirenEndOfContractEarlyRedemptionInitialState: SirenDictionnaryType<EndOfContractEarlyRedemptionState> = {};

export const EndOfContractEarlyRedemptionReducer: Reducer<SirenDictionnaryType<EndOfContractEarlyRedemptionState>> = (state = sirenEndOfContractEarlyRedemptionInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                typeofbuyback: action.payload.typeofbuyback,
                redemptionDate: action.payload.redemptionDate,
                files: action.payload.files,
                materials: action.payload.materials,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...endOfContractEarlyRedemptionInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EARLY_REDEMPTION/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
