import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentAccountExtractInitialState = {
    contractIds: [] as string[],
    start: undefined as Date | undefined,
    end: undefined as Date | undefined,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type DocumentAccountExtractState = typeof documentAccountExtractInitialState;
export const sirenDocumentAccountExtractInitialState: SirenDictionnaryType<DocumentAccountExtractState> = {};

export const DocumentAccountExtractReducer: Reducer<SirenDictionnaryType<DocumentAccountExtractState>> = (state = sirenDocumentAccountExtractInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, { 
                contractIds: action.payload.contractIds,
                start: action.payload.start,
                end: action.payload.end,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, { 
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },
            });
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, { 
                ...documentAccountExtractInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/DOCUMENT/ACCOUNT_EXTRACT/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, { 
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;

    }
};