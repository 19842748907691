export function createOpenModal(name: string) {
    return {
        type: '@MODAL/OPEN_MODAL',
        name,
    } as const;
}

export function createCloseModal(name: string) {
    return {
        type: '@MODAL/CLOSE_MODAL',
        name,
    } as const;
}

export function createToggleModal(name: string) {
    return {
        type: '@MODAL/TOGGLE_MODAL',
        name,
    } as const;
}

export type ModalsAction =
    | ReturnType<typeof createOpenModal>
    | ReturnType<typeof createCloseModal>
    | ReturnType<typeof createToggleModal>;