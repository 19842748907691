
export function createSetNavigateAsPersonsSearchTerms(searchTerms?: string | null) {
    return {
        type: '@PERSONS/SET_NAVIGATE_AS_SEARCH_TERMS',
        payload: {
            searchTerms,
        },
    } as const;
}

export const createFetchNavigateAsPersonsAction = () => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS',
    } as const;
};

export const createFetchNavigateAsPersonsSuccessAction = (icIds: string[]) => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_SUCCESS',
        payload: {
            icIds,
        },
    } as const;
};

export const createFetchNavigateAsPersonsFailureAction = () => {
    return {
        type: '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_FAILURE',
    } as const;
};

export const createFetchPersonAction = (icId: string) => {
    return {
        type: '@PERSONS/FETCH_PERSON',
        payload: {
            icId,
        },
    } as const;
};

export const createFetchPersonSuccessAction = (icId: string) => {
    return {
        type: '@PERSONS/FETCH_PERSON_SUCCESS',
        payload: {
            icId,
        },
    } as const;
};

export const createFetchPersonFailureAction = (icId: string) => {
    return {
        type: '@PERSONS/FETCH_PERSON_FAILURE',
        payload: {
            icId,
        },
    } as const;
};

export type PersonsAction =
    | ReturnType<typeof createSetNavigateAsPersonsSearchTerms>
    | ReturnType<typeof createFetchNavigateAsPersonsAction>
    | ReturnType<typeof createFetchNavigateAsPersonsSuccessAction>
    | ReturnType<typeof createFetchNavigateAsPersonsFailureAction>
    | ReturnType<typeof createFetchPersonAction>
    | ReturnType<typeof createFetchPersonSuccessAction>
    | ReturnType<typeof createFetchPersonFailureAction>;
