import { ApiRequest, Reducer } from 'store';
import { CustomerRequestsCount, CustomerRequestStatus } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';

export const initialState = {
    customerRequestsByContractId: {} as { [id: string]: ApiRequest<string[]> },
    customerRequests: {} as ApiRequest<string[]>,
    statuses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<CustomerRequestStatus[]>,
    count: {} as { [id: string]: ApiRequest<CustomerRequestsCount> },
    globalCount: {} as ApiRequest<CustomerRequestsCount>,
} as const;

export type CustomerRequestsState = typeof initialState;

export const sirenState: SirenDictionnaryType<CustomerRequestsState> = {};

export const CustomerRequestsReducer: Reducer<SirenDictionnaryType<CustomerRequestsState>> = (state = sirenState, action) => {
    switch (action.type) {
        case '@CUSTOMER_REQUESTS/GET_REQUEST_BY_CONTRACT_ID':
            return sirenStatePatcher(state, action.payload.siren, {
                customerRequestsByContractId: {
                    ...state[action.payload.siren]?.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/GET_REQUEST_BY_CONTRACT_ID_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequestsByContractId: {
                    ...state[action.payload.siren]?.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.data,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/GET_REQUEST_BY_CONTRACT_ID_FAILURE':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequestsByContractId: {
                    ...state[action.payload.siren]?.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/GET_REQUEST':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequests: {
                    isFetching: true,
                    didInvalidate: true,
                },
            });

        case '@CUSTOMER_REQUESTS/GET_REQUEST_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequests: {
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.data,
                },
            });

        case '@CUSTOMER_REQUESTS/GET_REQUEST_FAILURE':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequests: {
                    isFetching: false,
                    didInvalidate: true,
                },
            });

        case '@CUSTOMER_REQUESTS/GET_COUNT':
            return sirenStatePatcher(state, action.payload.siren,  {
                globalCount: {
                    isFetching: true,
                    didInvalidate: true,
                },
            });

        case '@CUSTOMER_REQUESTS/GET_COUNT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren,  {
                globalCount: {
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.data,
                },
            });

        case '@CUSTOMER_REQUESTS/GET_COUNT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren,  {
                globalCount: {
                    isFetching: false,
                    didInvalidate: true,
                },
            });

        case '@CUSTOMER_REQUESTS/GET_COUNT_BY_ID':
            return sirenStatePatcher(state, action.payload.siren,  {
                count: {
                    ...state[action.payload.siren]?.count,
                    [action.payload.contractId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/GET_COUNT_BY_ID_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren,  {
                count: {
                    ...state[action.payload.siren]?.count,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: false,
                        data: action.payload.data,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/GET_COUNT_BY_ID_FAILURE':
            return sirenStatePatcher(state, action.payload.siren,  {
                count: {
                    ...state[action.payload.siren]?.count,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/INVALIDATE_LIST_BY_ID':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequestsByContractId: {
                    ...state[action.payload.siren]?.customerRequestsByContractId,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
                count: {
                    ...state[action.payload.siren]?.count,
                    [action.payload.contractId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            });

        case '@CUSTOMER_REQUESTS/INVALIDATE_LIST':
            return sirenStatePatcher(state, action.payload.siren,  {
                customerRequests: {
                    isFetching: false,
                    didInvalidate: true,
                },
                globalCount: {
                    isFetching: false,
                    didInvalidate: true,
                },
            });
        case '@CUSTOMER_REQUESTS/FETCH_CUSTOMER_REQUESTS_STATUSES':
            return sirenStatePatcher(state, action.payload.siren,  {
                statuses: {
                    ...state[action.payload.siren]?.statuses,
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@CUSTOMER_REQUESTS/FETCH_CUSTOMER_REQUESTS_STATUSES_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren,  {
                statuses: {
                    ...state[action.payload.siren]?.statuses,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.statuses,
                },
            });

        case '@CUSTOMER_REQUESTS/FETCH_CUSTOMER_REQUESTS_STATUSES_FAILURE':
            return sirenStatePatcher(state, action.payload.siren,  {
                statuses: {
                    ...state[action.payload.siren]?.statuses,
                    didInvalidate: true,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
