import { useAppContext } from 'components/common/AppProvider';
import { ToastMessage } from 'components/common/ToastMessage';
import { WidgetLazyLoader } from 'components/common/WidgetLazyLoader';
import { configuration } from 'config/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RoutePaths } from 'routes/RoutePaths';
import { getNavigateAs, removeNavigateAs, setNavigateAs } from 'services/NavigateAsService';
import { hasRight, Permissions } from 'services/RightsService';
import { createSignOutAction } from 'store/AppContext/AppContextActions';
import { getAppContext } from 'store/AppContext/AppContextThunk';
import { createOpenModal } from 'store/Modals/ModalsActions';
import { FranfinanceSelectors } from 'store/Normalizr/Selectors';
import { getPerson } from 'store/Persons/PersonsThunk';
import { NavigateAsModal, NavigateAsModalName } from './NavigateAsModal';

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-account-center': {
                id?: string;
                'available-languages'?: string;
                'user-dropdown-links'?: any;
                authentication?: string;
                environment?: string;
                language?: string;
                debug?: string;
                'hide-services-link': boolean;
                mode: string;
                ref?: (elt: HTMLElement) => void;
                'show-sign-in-button'?: boolean;
                'navigate-as'?: boolean;
                'navigate-as-list'?: string;
                'navigate-as-user'?: string;
            };
        }
    }

    interface DocumentEventMap {
        [navigateAsLinkClickedEventName]: MouseEvent;
        [navigateAsEventName]: CustomEvent<INavigateAsEvent>;
        [stopNavigateAsEventName]: Event;
        [languageChangedEventName]: CustomEvent<ILanguageChangedEvent>;
        [signOutEventName]: MouseEvent;
    }
}

const navigateAsLinkClickedEventName = 'sgwt-account-center--navigate-as-link-clicked';
const navigateAsEventName = 'sgwt-account-center--navigate-as-select-user';
const stopNavigateAsEventName = 'sgwt-account-center--stop-navigation-as';
const languageChangedEventName = 'sgwt-account-center--language-changed';
const signOutEventName = 'sgwt-account-center--sign-out';

interface INavigateAsEvent {
    user: {
        id: string,
        name: string,
        company: string,
    }
}

interface ILanguageChangedEvent {
    language: string,
}

export const SgwtAccountCenter: React.FC = () => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const {
        dispatch,
        state: {
            appContext: {
                environment,
                loggedUserId,
                didInvalidate,
                isFetching,
            },
            entities,
        },
    } = useAppContext();
    const loggedUser = FranfinanceSelectors.getLoggedUser(loggedUserId, entities.franFinance);
    if (loggedUserId && !loggedUser?.impersonatedBy && getNavigateAs() && !isFetching && !didInvalidate) {
        setTimeout(() => {
            toast.error(
                <ToastMessage
                    title={t('errors:Impersonation Failed')}
                    message={t('errors:Impersonation failed Please come back later to use this functionnality')}
                />,
                { toastId: 'Impersonation Failed' },
            );
            removeNavigateAs();
        }, 3000);
    }
    const navigateAsIcId = getNavigateAs();
    const navigateAsPerson = FranfinanceSelectors.getPerson(navigateAsIcId, entities.franFinance);
    const canNavigateAs = hasRight(loggedUser?.impersonatedBy || loggedUser, Permissions.Administrator);

    const handleOpenNavigateAs = () => {
        dispatch(createOpenModal(NavigateAsModalName));
    };

    const handleNavigateAs = (event: CustomEvent<INavigateAsEvent>) => {
        setNavigateAs(event.detail.user.id);
    };

    const handleStopNavigateAs = () => {
        removeNavigateAs();
    };

    const handleLanguageChange = (event: CustomEvent<ILanguageChangedEvent>) => {
        i18n.changeLanguage(event.detail.language);
    };

    const handleSignOut = () => {
        dispatch(createSignOutAction());
        removeNavigateAs();
        navigate(RoutePaths.Home.url());
    };

    useEffect(() => {
        dispatch(getAppContext()).catch(() => void 0);
        document.addEventListener(navigateAsLinkClickedEventName, handleOpenNavigateAs);
        document.addEventListener(navigateAsEventName, handleNavigateAs);
        document.addEventListener(stopNavigateAsEventName, handleStopNavigateAs);
        document.addEventListener(languageChangedEventName, handleLanguageChange);
        document.addEventListener(signOutEventName, handleSignOut);

        return () => {
            document.removeEventListener(navigateAsLinkClickedEventName, handleOpenNavigateAs);
            document.removeEventListener(navigateAsEventName, handleNavigateAs);
            document.removeEventListener(stopNavigateAsEventName, handleStopNavigateAs);
            document.removeEventListener(languageChangedEventName, handleLanguageChange);
            document.removeEventListener(signOutEventName, handleSignOut);
        };
    }, []);

    useEffect(() => {
        if (canNavigateAs && navigateAsIcId) {
            dispatch(getPerson(navigateAsIcId)).catch(() => void 0);
        }
    }, [canNavigateAs]);

    const navigateAsUser = navigateAsPerson && {
        id: navigateAsPerson.icId,
        name: `${navigateAsPerson.lastName} ${navigateAsPerson.firstName}`,
    };

    return (
        <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-account-center/v4/sgwt-account-center.js`}>
            <sgwt-account-center
                id="sgwtAccountCenter"
                language="fr"
                available-languages="fr"
                authentication="sg-connect-v2"
                producer-code="franfinance_enterprise"
                hide-services-link={true}
                mode="b2b2c"
                environment={environment?.toUpperCase() === 'PRODUCTION' ? undefined : environment || undefined}
                show-sign-in-button={true}
                navigate-as={canNavigateAs || undefined}
                navigate-as-user={JSON.stringify(navigateAsUser)}
            />
            {canNavigateAs ? <NavigateAsModal /> : null}
        </WidgetLazyLoader>
    );
};
