import * as React from 'react';
import { ToastContentProps } from 'react-toastify';
import { displayDate } from 'services/Date';

export type ToastMessageProps = Partial<ToastContentProps> & {
    title?: string | null;
    message?: string | null;
};

export const ToastMessage: React.FC<ToastMessageProps> = ({ title, message, closeToast, toastProps }) => {
    let toastTitle = title;
    if (!title) {
        switch (toastProps?.type) {
            case 'error':
                toastTitle = 'Error';
                break;

            case 'warning':
                toastTitle = 'Warning';
                break;

            case 'success':
                toastTitle = 'Success';
                break;

            default:
                toastTitle = 'Information';
                break;
        }
    }

    return (
        <>
            <div className="notification-header">
                <span className="fw-medium me-auto">{toastTitle}</span>
                <span className="notification-timestamp">
                    {displayDate(new Date(), 'With diff from now')}
                </span>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeToast} />
            </div>
            {message && <div
                className="notification-body text-primary"
                style={{ whiteSpace: 'pre-line' }}
            >{message}</div>}
        </>
    );
};
