import { CurrentForm, NavType } from 'components/Pages/CustomerService/Modal/FormType';
import { CustomerServiceModalStep } from 'services/CustomerService';
import { Reducer } from 'store/configureStore';
import { combineReducers } from 'store/storeHelper';
import { BillingBankDetailsReducer, sirenBillingBankDetailsInitialState } from './Billing/BankDetails/BankDetailsReducer';
import { BillingBillingAddressReducer, sirenBillingBillingAddressInitialState } from './Billing/BillingAddress/BillingAddressReducer';
import { BillingOrderNumberReducer, sirenBillingOrderNumberInitialState } from './Billing/OrderNumber/OrderNumberReducer';
import { ChangeBankDetailsReducer, sirenChangeBankDetailsInitialState } from './Change/BankDetails/BankDetailsReducer';
import { ChangeContractTransferReducer, sirenChangeContractTransferInitialState } from './Change/ContractTransfer/ContractTransferReducer';
import { ChangeFinancialConditionReducer, sirenChangeFinancialConditionInitialState } from './Change/FinancialCondition/FinancialConditionReducer';
import { ChangeHeadOfficeAddressReducer, sirenChangeHeadOfficeAddressInitialState } from './Change/HeadOfficeAddress/HeadOfficeAddressReducer';
import { CustomerServiceAction } from './CustomerServiceActions';
import { DocumentAccountExtractReducer, sirenDocumentAccountExtractInitialState } from './Document/AccountExtract/AccountExtractReducer';
import { DocumentContractRelatedReducer, sirenDocumentContractRelatedInitialState } from './Document/ContractRelated/ContractRelatedReducer';
import { DocumentContractualCopyReducer, sirenDocumentContractualCopyInitialState } from './Document/ContractualCopy/ContractualCopyReducer';
import { DocumentEquipmentRelatedReducer, sirenDocumentEquipmentRelatedStateInitialState } from './Document/EquipmentRelated/EquipmentRelatedReducer';
import { EndOfContractContractTransferReducer, sirenEndOfContractContractTransferInitialState } from './EndOfContract/ContractTransfer/ContractTransferReducer';
import { EndOfContractDenialOfContractRenewalReducer, sirenEndOfContractDenialOfContractRenewalInitialState } from './EndOfContract/DenialOfContractRenewal/DenialOfContractRenewalReducer';
import { EndOfContractEarlyRedemptionReducer, sirenEndOfContractEarlyRedemptionInitialState } from './EndOfContract/EarlyRedemption/EarlyRedemptionReducer';
import { EndOfContractExerciseOfPurchaseOptionReducer, sirenEndOfContractExerciseOfPurchaseOptionInitialState } from './EndOfContract/ExerciseOfPurchaseOption/ExerciseOfPurchaseOptionReducer';
import { EndOfContractReturnOfEquipmentReducer, sirenEndOfContractReturnOfEquipmentInitialState } from './EndOfContract/ReturnOfEquipment/ReturnOfEquipmentReducer';
import { EndOfContractTerminationOfContractReducer, sirenEndOfContractTerminationOfContractInitialState } from './EndOfContract/TerminationOfContract/TerminationOfContractReducer';

const formsInitialState = {
    documentAccountExtract: sirenDocumentAccountExtractInitialState,
    documentContractRelated: sirenDocumentContractRelatedInitialState,
    documentContractualCopy: sirenDocumentContractualCopyInitialState,
    documentEquipmentRelated: sirenDocumentEquipmentRelatedStateInitialState,
    endOfContractReturnOfEquipment: sirenEndOfContractReturnOfEquipmentInitialState,
    endOfContractEarlyRedemption: sirenEndOfContractEarlyRedemptionInitialState,
    endOfContractTerminationOfContract: sirenEndOfContractTerminationOfContractInitialState,
    endOfContractDenialOfContractRenewal: sirenEndOfContractDenialOfContractRenewalInitialState,
    endOfContractContractTransfer: sirenEndOfContractContractTransferInitialState,
    billingBillingAddress: sirenBillingBillingAddressInitialState,
    billingBankDetails: sirenBillingBankDetailsInitialState,
    billingOrderNumber: sirenBillingOrderNumberInitialState,
    changeBankDetails: sirenChangeBankDetailsInitialState,
    changeContractTransfer: sirenChangeContractTransferInitialState,
    changeHeadOfficeAddress: sirenChangeHeadOfficeAddressInitialState,
    changeFinancialCondition: sirenChangeFinancialConditionInitialState,
    endOfContractExerciseOfPurchaseOption: sirenEndOfContractExerciseOfPurchaseOptionInitialState,
};
type FormsState = typeof formsInitialState;

const modalInitialState = {
    step: CustomerServiceModalStep.CategorySelection as CustomerServiceModalStep,
};
type ModalState = typeof modalInitialState;

const currentFormInitialState = {
    nav: NavType.Document,
    type: undefined,
} as CurrentForm;
type CurrentFormState = typeof currentFormInitialState;

export const initialState = {
    currentForm: currentFormInitialState,
    forms: formsInitialState,
    modal: modalInitialState,
} as const;

export type CustomerServiceState = typeof initialState;

const FormsReducers = combineReducers<FormsState, CustomerServiceAction>({
    documentAccountExtract: DocumentAccountExtractReducer,
    documentContractRelated: DocumentContractRelatedReducer,
    documentContractualCopy: DocumentContractualCopyReducer,
    documentEquipmentRelated: DocumentEquipmentRelatedReducer,
    endOfContractReturnOfEquipment: EndOfContractReturnOfEquipmentReducer,
    endOfContractEarlyRedemption: EndOfContractEarlyRedemptionReducer,
    endOfContractTerminationOfContract: EndOfContractTerminationOfContractReducer,
    endOfContractDenialOfContractRenewal: EndOfContractDenialOfContractRenewalReducer,
    endOfContractContractTransfer: EndOfContractContractTransferReducer,
    billingBillingAddress: BillingBillingAddressReducer,
    billingBankDetails: BillingBankDetailsReducer,
    billingOrderNumber: BillingOrderNumberReducer,
    changeBankDetails: ChangeBankDetailsReducer,
    changeContractTransfer: ChangeContractTransferReducer,
    changeHeadOfficeAddress: ChangeHeadOfficeAddressReducer,
    changeFinancialCondition: ChangeFinancialConditionReducer,
    endOfContractExerciseOfPurchaseOption: EndOfContractExerciseOfPurchaseOptionReducer,
});

const ModalReducer: Reducer<ModalState> = (state = modalInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/SET_MODAL_STEP':
            return {
                ...state,
                step: action.payload.modalStep,
            };
        case '@CUSTOMER_SERVICE/RESET':
            return {
                ...state,
                step: CustomerServiceModalStep.CategorySelection,
            };
        case '@CUSTOMER_SERVICE/SET_CURRENT_FORM':
            return {
                ...state,
                step: action.payload.currentForm.type
                    ? state.step
                    : CustomerServiceModalStep.CategorySelection,
            };
        default:
            return state;
    }
};

const CurrentFormReducer: Reducer<CurrentFormState> = (state = currentFormInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/SET_CURRENT_FORM':
            return {
                ...action.payload.currentForm,
            };
        default:
            return state;
    }
};

export const CustomerServiceReducer = combineReducers<CustomerServiceState, CustomerServiceAction>({
    currentForm: CurrentFormReducer,
    forms: FormsReducers,
    modal: ModalReducer,
});
