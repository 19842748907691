import { ValidityPeriod } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const billingOrderNumberInitialState = {
    contractIds: [] as string[],
    orderNumber: '' as string,
    validityPeriod: ValidityPeriod.ContractDuration as ValidityPeriod,
    orderFormFile: undefined as File | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

export type BillingOrderNumberState = typeof billingOrderNumberInitialState;
export const sirenBillingOrderNumberInitialState: SirenDictionnaryType<BillingOrderNumberState> = {};

export const BillingOrderNumberReducer: Reducer<SirenDictionnaryType<BillingOrderNumberState>> = (state = sirenBillingOrderNumberInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                orderNumber: action.payload.orderNumber,
                validityPeriod: action.payload.validityPeriod,
                orderFormFile: action.payload.orderFormFile,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },
            });
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...billingOrderNumberInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/BILLING/ORDER_NUMBER/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
