import { DocumentAccountExtractDetails } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentAccountExtractInitialState = {
    results: {} as { [id: string]: ApiRequest<DocumentAccountExtractDetails> },
} as const;

type DocumentAccountExtractState = typeof documentAccountExtractInitialState;

export const DocumentAccountExtractReducer: Reducer<DocumentAccountExtractState> = (state = documentAccountExtractInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/ACCOUNT_EXTRACT/GET':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/ACCOUNT_EXTRACT/GET_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/ACCOUNT_EXTRACT/GET_FAILURE':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;

    }
};