import { BillingAddressDetails } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const billingAddressInitialState = {
    results: {} as { [id: string]: ApiRequest<BillingAddressDetails> },
} as const;

type BillingAddressState = typeof billingAddressInitialState;

export const BillingAddressReducer: Reducer<BillingAddressState> = (state = billingAddressInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_REQUEST_DETAILS/BILLING/ADDRESS/GET':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/BILLING/ADDRESS/GET_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/BILLING/ADDRESS/GET_FAILURE':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;

    }
};