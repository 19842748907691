import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractDenialOfContractRenewalInitialState = {
    contractIds: [] as string[],
    termsAccepted: false as boolean,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

export type EndOfContractDenialOfContractRenewalState = typeof endOfContractDenialOfContractRenewalInitialState;
export const sirenEndOfContractDenialOfContractRenewalInitialState: SirenDictionnaryType<EndOfContractDenialOfContractRenewalState> = {};

export const EndOfContractDenialOfContractRenewalReducer: Reducer<SirenDictionnaryType<EndOfContractDenialOfContractRenewalState>> = (state = sirenEndOfContractDenialOfContractRenewalInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                termsAccepted: action.payload.termsAccepted,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },

            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...endOfContractDenialOfContractRenewalInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/DENIAL_OF_CONTRACT_RENEWAL/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
