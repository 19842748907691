import { Reducer } from 'store';
const unloadedState: {
    readonly [name: string]: boolean;
} = {
} as const;

export type ModalState = typeof unloadedState;

export const ModalsReducer: Reducer<ModalState> = (state = unloadedState, action) => {
    switch (action.type) {
        case '@MODAL/OPEN_MODAL':
            return {
                ...state,
                [action.name]: true,
            };

        case '@MODAL/CLOSE_MODAL':
            return {
                ...state,
                [action.name]: false,
            };

        case '@MODAL/TOGGLE_MODAL':
            return {
                ...state,
                [action.name]: !state[action.name],
            };

        default:
            return state;
    }
};