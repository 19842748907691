import * as React from 'react';
import { configuration } from 'config/constants';
import { WidgetLazyLoader } from '../WidgetLazyLoader';

interface Props {
    siteId?: string;
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            'sgwt-web-analytics': {
                'site-id': number;
            };
        }
    }
}

export const SgwtWebAnalytics: React.FC<Props> = ({
    siteId,
}) => {
    if (siteId) {
        return (
            <WidgetLazyLoader script={`${configuration.widgetCdnBaseUrl}/widgets/sgwt-web-analytics/v4/sgwt-web-analytics.js`}>
                <sgwt-web-analytics site-id={parseInt(siteId, 10)} />
            </WidgetLazyLoader>
        );
    }
    return null;
};
