import { EndOfContractReturnOfEquipmentDetails } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractReturnOfEquipmentInitialState = {
    results: {} as { [id: string]: ApiRequest<EndOfContractReturnOfEquipmentDetails> },
} as const;

type EndOfContractReturnOfEquipmentState = typeof endOfContractReturnOfEquipmentInitialState;

export const EndOfContractReturnOfEquipmentReducer: Reducer<EndOfContractReturnOfEquipmentState> = (state = endOfContractReturnOfEquipmentInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_REQUEST_DETAILS/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/GET':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/GET_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/END_OF_CONTRACT/RETURN_OF_EQUIPMENT/GET_FAILURE':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;

    }
};