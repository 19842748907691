import { Amount, ContractAssets, ContractBilling, ContractInformation, ContractSortingSource, ContractsStatus, SearchContractRequest, SortDirection } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { ApiRequest, Reducer } from 'store';
import { SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';

export const DEFAULT_SEARCH_CONTRACTS_TAKE = 10;

export const DEFAULT_SEARCH_CONTRACTS_FILTERS = {
    skip: 0,
    take: DEFAULT_SEARCH_CONTRACTS_TAKE,
    sortDirection: SortDirection.Desc,
    sortSource: ContractSortingSource.BusinessNumber,
};

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_CONTRACTS_FILTERS,
        },
        total: 0,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & {
        filters: SearchContractRequest,
        total: number,
    },
    contracts: {
        information: {} as { [id: string]: ApiRequest<ContractInformation> },
        assets: {} as { [id: string]: ApiRequest<ContractAssets> },
        billing: {} as { [id: string]: ApiRequest<ContractBilling> },
    },
    statuses: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<ContractsStatus[]>,
    ids: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]>,
    services: {
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<Amount>,
} as const;

export type ContractsState = typeof initialState;

export const sirenState: SirenDictionnaryType<ContractsState> = {};

export const ContractsReducer: Reducer<SirenDictionnaryType<ContractsState>> = (state = sirenState, action) => {
    switch (action.type) {
        case '@CONTRACTS/SET_SEARCH_CONTRACTS_SEARCH_TERMS':
            if (state[action.payload.siren]?.search?.filters.businessNumber === action.payload.businessNumber) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        businessNumber: action.payload.businessNumber,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_CHANGE_PAGE':
            if (state[action.payload.siren]?.search?.filters.skip === action.payload.skip
                && state[action.payload.siren]?.search?.filters.take === action.payload.take
            ) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_SORT':
            if (state[action.payload.siren]?.search?.filters.sortDirection === action.payload.sortDirection
                && state[action.payload.siren]?.search?.filters.sortSource === action.payload.sortSource
            ) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        sortDirection: action.payload.sortDirection,
                        sortSource: action.payload.sortSource,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_FINANCIAL_TYPE':
            if (state[action.payload.siren]?.search?.filters.financialType === action.payload.financialType) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        financialType: action.payload.financialType,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_STATUS_TYPE':
            if (state[action.payload.siren]?.search?.filters.status === action.payload.status) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        status: action.payload.status,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_MANAGEMENT_COMPANY':
            if (state[action.payload.siren]?.search?.filters.managementCompany === action.payload.managementCompany) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        managementCompany: action.payload.managementCompany,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_END_DATE_MIN':
            if (state[action.payload.siren]?.search?.filters.endDateMin === action.payload.endDateMin) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        endDateMin: action.payload.endDateMin,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SET_SEARCH_CONTRACTS_END_DATE_MAX':
            if (state[action.payload.siren]?.search?.filters.endDateMax === action.payload.endDateMax) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                        endDateMax: action.payload.endDateMax,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/RESET_SEARCH_CONTRACTS':
            const isAlreadyDefaultFilter = state[action.payload.siren]?.search?.filters && Object.entries(state[action.payload.siren]?.search?.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_CONTRACTS_TAKE;
                    case 'sortDirection':
                        return filterValue === SortDirection.Desc;
                    case 'sortSource':
                        return filterValue === ContractSortingSource.BusinessNumber;
                    default:
                        return !filterValue;
                }
            });
            if (isAlreadyDefaultFilter) {
                return state;
            }

            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...DEFAULT_SEARCH_CONTRACTS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                    total: 0,
                },
            });

        case '@CONTRACTS/SEARCH_CONTRACTS':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters ?? DEFAULT_SEARCH_CONTRACTS_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: true,
                    total: 0,
                },
            });

        case '@CONTRACTS/SEARCH_CONTRACTS_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.contractIds,
                    total: action.payload.total,
                },
            });

        case '@CONTRACTS/SEARCH_CONTRACTS_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: true,
                    isFetching: false,
                    data: undefined,
                },
            });

        case '@CONTRACTS/GET_CONTRACT_INFORMATION':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    information: {
                        ...state[action.payload.siren]?.contracts?.information,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                    assets: { ...state[action.payload.siren]?.contracts?.assets },
                    billing: { ...state[action.payload.siren]?.contracts?.billing },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_INFORMATION_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    information: {
                        ...state[action.payload.siren]?.contracts?.information,
                        [action.payload.contractId]: {
                            didInvalidate: false,
                            isFetching: false,
                        },
                    },
                    assets: { ...state[action.payload.siren]?.contracts?.assets },
                    billing: { ...state[action.payload.siren]?.contracts?.billing },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_INFORMATION_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    information: {
                        ...state[action.payload.siren]?.contracts?.information,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                    assets: { ...state[action.payload.siren]?.contracts?.assets },
                    billing: { ...state[action.payload.siren]?.contracts?.billing },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_ASSETS':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    assets: {
                        ...state[action.payload.siren]?.contracts?.assets,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                    information: { ...state[action.payload.siren]?.contracts?.information },
                    billing: { ...state[action.payload.siren]?.contracts?.billing },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_ASSETS_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    assets: {
                        ...state[action.payload.siren]?.contracts?.assets,
                        [action.payload.contractId]: {
                            didInvalidate: false,
                            isFetching: false,
                        },
                    },
                    information: { ...state[action.payload.siren]?.contracts?.information },
                    billing: { ...state[action.payload.siren]?.contracts?.billing },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_ASSETS_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    assets: {
                        ...state[action.payload.siren]?.contracts?.assets,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                    information: { ...state[action.payload.siren]?.contracts?.information },
                    billing: { ...state[action.payload.siren]?.contracts?.billing },
                },
            });

        case '@CONTRACTS/GET_AMOUNT_TO_BE_REGULARIZED':
            return sirenStatePatcher(state, action.payload.siren, {
                services: {
                    ...state[action.payload.siren]?.services,
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@CONTRACTS/GET_AMOUNT_TO_BE_REGULARIZED_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                services: {
                    data: action.payload.amountToBeRegularized,
                    didInvalidate: false,
                    isFetching: false,
                },
            });

        case '@CONTRACTS/GET_AMOUNT_TO_BE_REGULARIZED_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                services: {
                    ...state[action.payload.siren]?.services,
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@CONTRACTS/GET_CONTRACT_BILLING':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    billing: {
                        ...state[action.payload.siren]?.contracts?.billing,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                    information: { ...state[action.payload.siren]?.contracts?.information },
                    assets: { ...state[action.payload.siren]?.contracts?.assets },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_BILLING_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    billing: {
                        ...state[action.payload.siren]?.contracts?.billing,
                        [action.payload.contractId]: {
                            didInvalidate: false,
                            isFetching: false,
                        },
                    },
                    information: { ...state[action.payload.siren]?.contracts?.information },
                    assets: { ...state[action.payload.siren]?.contracts?.assets },
                },
            });

        case '@CONTRACTS/GET_CONTRACT_BILLING_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                contracts: {
                    ...state[action.payload.siren]?.contracts,
                    billing: {
                        ...state[action.payload.siren]?.contracts?.billing,
                        [action.payload.contractId]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                    information: { ...state[action.payload.siren]?.contracts?.information },
                    assets: { ...state[action.payload.siren]?.contracts?.assets },
                },
            });

        case '@CONTRACTS/FETCH_CONTRACTS_STATUSES':
            return sirenStatePatcher(state, action.payload.siren, {
                statuses: {
                    ...state[action.payload.siren]?.statuses,
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@CONTRACTS/FETCH_CONTRACTS_STATUSES_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                statuses: {
                    ...state[action.payload.siren]?.statuses,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.statuses,
                },
            });

        case '@CONTRACTS/FETCH_CONTRACTS_STATUSES_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                statuses: {
                    ...state[action.payload.siren]?.statuses,
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@CONTRACTS/FETCH_CONTRACTS_IDS':
            return sirenStatePatcher(state, action.payload.siren, {
                ids: {
                    ...state[action.payload.siren]?.ids,
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@CONTRACTS/FETCH_CONTRACTS_IDS_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ids: {
                    ...state[action.payload.siren]?.ids,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.ids,
                },
            });

        case '@CONTRACTS/FETCH_CONTRACTS_IDS_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                ids: {
                    ...state[action.payload.siren]?.ids,
                    didInvalidate: true,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
