import { isArray } from 'lodash-es';

export class PromiseStore {
    public static get<T = void>(...keyArgs: any[]): Promise<T> | undefined {
        const key = PromiseStore.getKey(keyArgs);
        return PromiseStore.store[key] ? PromiseStore.store[key] as Promise<T> : undefined;
    }

    public static set<T>(promise: Promise<T>, ...keyArgs: any[]): void {
        const key = PromiseStore.getKey(keyArgs);
        PromiseStore.store[key] = promise;
    }

    public static clear(): void {
        PromiseStore.store = {};
    }

    private static store: { [key: string]: Promise<any> | undefined } = {};

    private static getKey(...keyArgs: any[]) : string {
        const reducedKeyArgs = keyArgs && keyArgs.map((keyArg) => {
            if (keyArg && isArray(keyArg)) {
                return keyArgs.sort().join('-');
            }
            return keyArg;
        }) || [];
        return `-${reducedKeyArgs.join('_')}`;
    }
}
