export const navigateAsKey = 'SGM-Impersonating';

export const setNavigateAs = (icId: string) => {
    if (typeof sessionStorage === 'undefined') {
        return;
    }
    sessionStorage.setItem(navigateAsKey, icId);
    window.location.reload();
};

export const getNavigateAs = (url?: RequestInfo) => {
    if (typeof sessionStorage === 'undefined' || url?.toString()?.includes('/api/v1/persons')) {
        return;
    }
    return sessionStorage.getItem(navigateAsKey);
};

export const removeNavigateAs = () => {
    if (typeof sessionStorage === 'undefined') {
        return;
    }
    if (sessionStorage.getItem(navigateAsKey)) {
        sessionStorage.removeItem(navigateAsKey);
        window.location.reload();
    }
};
