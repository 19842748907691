import { EndOfContractTerminationOfContractDetails } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractTerminationOfContractInitialState = {
    results: {} as { [id: string]: ApiRequest<EndOfContractTerminationOfContractDetails> },
} as const;

type EndOfContractTerminationOfContractState = typeof endOfContractTerminationOfContractInitialState;

export const EndOfContractTerminationOfContractReducer: Reducer<EndOfContractTerminationOfContractState> = (state = endOfContractTerminationOfContractInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_REQUEST_DETAILS/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/GET':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/GET_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/GET_FAILURE':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;

    }
};