import { FinancialType, SimulationResponse } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { ApiRequest, Reducer } from 'store';
import { SimulationsAction } from './SimulationsActions';
import { SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';

export const initialState = {
    basic: {} as Partial<Record<FinancialType, Record<number, Record<number, ApiRequest<SimulationResponse>>>>>,
} as const;

export type SimulationsState = typeof initialState;
export const sirenState: SirenDictionnaryType<SimulationsState> = {};

export const SimulationsReducer: Reducer<SirenDictionnaryType<SimulationsState>> = (state = sirenState, action) => {
    switch (action.type) {
        case '@SIMULATIONS/FETCH_SIMULATION':
            return sirenStatePatcher(state, action.payload.siren, {
                basic: createFetchBasicSimulationState(
                    state[action.payload.siren],
                    action.payload,
                    {
                        isFetching: true,
                        didInvalidate: true,
                    }),
            });

        case '@SIMULATIONS/FETCH_SIMULATION_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                basic: createFetchBasicSimulationState(
                    state[action.payload.siren],
                    action.payload,
                    {
                        isFetching: false,
                        didInvalidate: false,
                    },
                    action.payload.response,
                ),
            });

        case '@SIMULATIONS/FETCH_SIMULATION_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                basic: createFetchBasicSimulationState(
                    state[action.payload.siren],
                    action.payload,
                    {
                        isFetching: false,
                        didInvalidate: true,
                    }),
            });

        default:
            return state;
    }
};

const createFetchBasicSimulationState = (state: SimulationsState, payload: SimulationsAction['payload'], apiRequest: ApiRequest, response?: SimulationResponse) => {
    return {
        ...state?.basic,
        [payload.financialType]: {
            ...state?.basic[payload.financialType],
            ...state?.basic[payload.financialType],
            [payload.amount]: {
                ...state?.basic[payload.financialType]?.[payload.amount],
                [payload.duration]: {
                    ...state?.basic[payload.financialType]?.[payload.amount]?.[payload.duration],
                    ...apiRequest,
                    data: response,
                },
            },
        },
    };
};