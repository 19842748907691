export enum NavType {
    Document = 'Document',
    EndOfContract = 'EndOfContract',
    Billing = 'Billing',
    Change = 'Change',
}

export enum DocumentFormType {
    ContractRelated = 'ContractRelated',
    EquipmentRelated = 'EquipmentRelated',
    AccountExtract = 'AccountExtract',
    ContractualCopy = 'ContractualCopy',
}

export enum EndOfContractFormType {
    ReturnOfEquipment = 'ReturnOfEquipment',
    DenialOfContractRenewal = 'DenialOfContractRenewal',
    EarlyRedemption = 'EarlyRedemption',
    TerminationOfContract = 'TerminationOfContract',
    ContractTransfer = 'ContractTransfer',
    ExerciseOfPurchaseOption = 'ExerciseOfPurchaseOption',
}

export enum BillingFormType {
    BillingAddress = 'BillingAddress',
    BankDetails = 'BankDetails',
    OrderNumber = 'OrderNumber',
    // PaymentDetails = 'PaymentDetails'
}

export enum ChangeFormType {
    BankDetails = 'BankDetails',
    ContractTransfer = 'ContractTransfer',
    HeadOfficeAddress = 'HeadOfficeAddress',
    FinancialCondition = 'FinancialCondition'
}

export type FormType = DocumentFormType | EndOfContractFormType | BillingFormType | ChangeFormType;

const navFormMap = {
    [NavType.Document]: DocumentFormType,
    [NavType.Billing]: BillingFormType,
    [NavType.EndOfContract]: EndOfContractFormType,
    [NavType.Change]: ChangeFormType,
};

export const enforceKeyFor = (nav: NavType, form: FormType) => {
    const formTypes = navFormMap[nav];
    return form as keyof typeof formTypes;
};

export type NavFormTypeOf<T> = {
    [NavType.Document]: {
        [K in keyof typeof DocumentFormType]: T
    },
    [NavType.Billing]: {
        [K in keyof typeof BillingFormType]: T
    },
    [NavType.EndOfContract]: {
        [K in keyof typeof EndOfContractFormType]: T
    },
    [NavType.Change]: {
        [K in keyof typeof ChangeFormType]: T
    },
};

export type NavFormMap = {
    [NavType.Document]: DocumentFormType,
    [NavType.Billing]: BillingFormType,
    [NavType.EndOfContract]: EndOfContractFormType,
    [NavType.Change]: ChangeFormType,
};

type CurrentFormNav<Nav extends NavType> = {
    nav: Nav;
    type?: NavFormMap[Nav];
};

export type CurrentForm = CurrentFormNav<NavType.Billing>
    | CurrentFormNav<NavType.Change>
    | CurrentFormNav<NavType.Document>
    | CurrentFormNav<NavType.EndOfContract>;
