import i18n, { BackendModule, Services, ReadCallback, ResourceKey } from 'i18next';
import 'isomorphic-fetch';

export interface IOptions {
    loadPath: (language: string) => string;
    addPath: string;
    allowMultiLoading: boolean;
    parsePayload: (namespace: string, key: string, fallbackValue: string) => { [x: number]: unknown; };
}

function getDefaults(): IOptions {
    return {
        loadPath: () => '/locales/{{lng}}/{{ns}}.json',
        addPath: '/locales/add/{{lng}}/{{ns}}',
        allowMultiLoading: false,
        parsePayload: (_, key, fallbackValue) => ({ [key]: fallbackValue || '' }),
    };
}

class Backend implements BackendModule<Partial<IOptions>> {
    static type: string;
    type: 'backend';
    services: Services;
    options: IOptions = getDefaults();
    loading: { [lng: string]: boolean } = {};

    constructor(services: Services, options: Partial<IOptions> = {}) {
        this.type = 'backend';
        this.services = services;
        this.init(services, options);
    }

    init(services: Services, options: Partial<IOptions> = {}) {
        this.services = services;
        this.options = {
            ...this.options,
            ...options,
        };
    }

    read(language: string, namespace: string, callback: ReadCallback) {
        if (this.loading[language]) {
            return callback(null, {});
        }

        this.loading[language] = true;

        const loadPath = this.options.loadPath(language);
        if (!loadPath) {
            return callback(null, {});
        }

        const url = this.services.interpolator.interpolate(loadPath, { lng: language, ns: namespace }, language, {});
        this.loadUrl(language, url, callback);
    }

    create(languages: string[], namespace: string, key: string, fallbackValue: string) {
        const payload = this.options.parsePayload(namespace, key, fallbackValue);

        languages.forEach(lng => {
            const url = this.services.interpolator.interpolate(this.options.addPath, { lng: lng, ns: namespace }, lng, {});

            fetch(url, {
                body: JSON.stringify(payload),
            }).catch((err) => {
                console.error('[I18n Backend] Failed to create resource', err);
            });
        });
    }

    loadUrl(language: string, url: string, callback: ReadCallback) {
        fetch(url)
            .then((response) => {
                const { status } = response;

                if (status >= 300) {
                    return callback(new Error(`[I18n Backend] Failed to load resource: ${url}`), {});
                }

                if (!response || !response.text) {
                    return callback(new Error(`[I18n Backend] No data returned for ${url}`), {});
                }
                /*clone() prevents locking the response*/
                response.clone().text().then((responseText) => {
                    try {
                        const result = <{ [ns: string]: ResourceKey }>JSON.parse(responseText);
                        Object.keys(result).forEach((ns) => {
                            i18n.addResourceBundle(language, ns, result[ns], true, true);
                        });

                        callback(null, {});
                    } catch (err) {
                        callback(new Error(`[I18n Backend] Failed parsing ${url} to json\n${err}`), {});
                    }
                    this.loading[language] = false;
                });
            })
            .catch((err) => {
                this.loading[language] = false;
                callback(new Error(`[I18n Backend] Failed to load resource: ${url}\n${err}`), {});
            });
    }
}

Backend.type = 'backend';

export default Backend;
