import {
    Address,
    BillingAddressDetails,
    BillingBankDetailDetails,
    BillingOrderNumberDetails,
    ChangeFinancialConditionDetails,
    ChangeHeadOfficeAddressDetails,
    ContractBilling,
    ContractInformation,
    DocumentAccountExtractDetails,
    DocumentContractRelatedDetails,
    DocumentCopyOfContractualElementDetails,
    DocumentEquipmentRelatedDetails,
    EndOfContractDenialOfContractRenewalDetails,
    EndOfContractExerciseOfPurchaseOptionDetails,
    EndOfContractEarlyRedemptionDetails,
    EndOfContractReturnOfEquipmentDetails,
    FinancialRequestDetailed,
    FinancialRequestSummary,
    Funding,
    LoggedUser,
    Person,
    SaveFinancialResponse,
    Status,
    ContractTransferDetails,
    EndOfContractTerminationOfContractDetails,
    HealthStatusReportDetailed,
} from './FranfinanceApiClient';
import { ObjectsWithoutIdProperty, ObjectsWithoutIdPropertyEnum } from './FranfinanceApiSchema';

export class BaseFranfinanceApiSchema {
    protected static getId(value: ObjectsWithoutIdProperty, type: ObjectsWithoutIdPropertyEnum): string {
        switch (type) {
            case ObjectsWithoutIdPropertyEnum.ContractBilling:
                return (value as ContractBilling).contractId || '';

            case ObjectsWithoutIdPropertyEnum.ContractInformation:
                return (value as ContractInformation).id || '';

            case ObjectsWithoutIdPropertyEnum.LoggedUser:
                return (value as LoggedUser).id;

            case ObjectsWithoutIdPropertyEnum.Person:
                return (value as Person).icId;

            case ObjectsWithoutIdPropertyEnum.SaveFinancialResponse:
                return (value as SaveFinancialResponse).requestId || '';

            case ObjectsWithoutIdPropertyEnum.FinancialRequestDetailed:
                return (value as FinancialRequestDetailed).internalReferenceId || '';

            case ObjectsWithoutIdPropertyEnum.Funding:
                return (value as Funding).productCode || '';

            case ObjectsWithoutIdPropertyEnum.Status:
                return (value as Status).statusCode || '';

            case ObjectsWithoutIdPropertyEnum.FinancialRequestSummary:
                return (value as FinancialRequestSummary).internalReferenceId || '';

            case ObjectsWithoutIdPropertyEnum.DocumentContractRelatedDetails:
                return (value as DocumentContractRelatedDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.DocumentEquipmentRelatedDetails:
                return (value as DocumentEquipmentRelatedDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.BillingBankDetailDetails:
                return (value as BillingBankDetailDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.BillingOrderNumberDetails:
                return (value as BillingOrderNumberDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.BillingAddressDetails:
                return (value as BillingAddressDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.Address:
                return (value as Address).city || '';

            case ObjectsWithoutIdPropertyEnum.ThirdAddress:
                return (value as Address).city || '';

            case ObjectsWithoutIdPropertyEnum.DocumentAccountExtractDetails:
                return (value as DocumentAccountExtractDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.ChangeHeadOfficeAddressDetails:
                return (value as ChangeHeadOfficeAddressDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.DocumentCopyOfContractualElementDetails:
                return (value as DocumentCopyOfContractualElementDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.EndOfContractReturnOfEquipmentDetails:
                return (value as EndOfContractReturnOfEquipmentDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.ChangeFinancialConditionDetails:
                return (value as ChangeFinancialConditionDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.EndOfContractExerciseOfPurchaseOptionDetails:
                return (value as EndOfContractExerciseOfPurchaseOptionDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.EndOfContractDenialOfContractRenewalDetails:
                return (value as EndOfContractDenialOfContractRenewalDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.EndOfContractEarlyRedemptionDetails:
                return (value as EndOfContractEarlyRedemptionDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.ContractTransferDetails:
                return (value as ContractTransferDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.EndOfContractTerminationOfContractDetails:
                return (value as EndOfContractTerminationOfContractDetails).caseId || '';

            case ObjectsWithoutIdPropertyEnum.HealthStatusReportDetailed:
                return (value as HealthStatusReportDetailed).status;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const exhaustiveCheck: never = type;
        }
        return '';
    }
}
