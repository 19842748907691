import { FinancialRequestCount, FinancialRequestDetailed, FinancialRequestSummary, FinancialSortingSource, SortDirection } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';

export const createSubmitFinancialAction = (siren: number) => {
    return {
        type: '@FINANCIAL/SUBMIT',
        payload: {
            siren,
        },
    } as const;
};

export const createSubmitFinancialSuccessAction = (siren: number) => {
    return {
        type: '@FINANCIAL/SUBMIT_SUCCESS',
        payload: {
            siren,
        },
    } as const;
};

export const createSubmitFinancialFailureAction = (siren: number) => {
    return {
        type: '@FINANCIAL/SUBMIT_FAILURE',
        payload: {
            siren,
        },
    } as const;
};

export const createSetFilesAction = (siren: number, files: FileAndType[]) => {
    return {
        type: '@FINANCIAL/SET_FILES',
        payload: {
            siren,
            files,
        },
    } as const;
};

export const createClearFilesAction = (siren: number) => {
    return {
        type: '@FINANCIAL/CLEAR_FILES',
        payload: {
            siren,
        },
    } as const;
};

export const createFetchFiancialRequestByIdAction = (siren: number, requestId: number) => {
    return {
        type: '@FINANCIAL/GET_REQUEST_BY_ID',
        payload: {
            siren,
            requestId,
        },
    } as const;
};

export const createFetchFiancialRequestByIdSuccessAction = (siren: number, requestId: number, data: FinancialRequestDetailed) => {
    return {
        type: '@FINANCIAL/GET_REQUEST_BY_ID_SUCCESS',
        payload: {
            siren,
            requestId,
            data,
        },
    } as const;
};

export const createFetchFiancialRequestByIdFailureAction = (siren: number, requestId: number) => {
    return {
        type: '@FINANCIAL/GET_REQUEST_BY_ID_FAILURE',
        payload: {
            siren,
            requestId,
        },
    } as const;
};

export const createSearchFinancialRequestAction = (siren: number) => {
    return {
        type: '@FINANCIAL/SEARCH_FINANCIAL_REQUEST',
        payload: {
            siren,
        },
    } as const;
};

export const createSearchFinancialRequestSuccessAction = (siren: number, FinancialRequestsList: FinancialRequestSummary[], total: number) => {
    return {
        type: '@FINANCIAL/SEARCH_FINANCIAL_REQUEST_SUCCESS',
        payload: {
            siren,
            FinancialRequestsList,
            total,
        },
    } as const;
};

export const createSearchFinancialRequestFailureAction = (siren: number) => {
    return {
        type: '@FINANCIAL/SEARCH_FINANCIAL_REQUEST_FAILURE',
        payload: {
            siren,
        },
    } as const;
};

export const createSetSearchFinancialRequestChangePageAction = (siren: number, skip: number, take: number) => {
    return {
        type: '@FINANCIAL/SET_SEARCH_FINANCIAL_REQUEST_CHANGE_PAGE',
        payload: {
            siren,
            skip,
            take,
        },
    } as const;
};

export const createSetSearchFinancialSortAction = (siren: number, sortDirection: SortDirection, sortSource: FinancialSortingSource) => {
    return {
        type: '@FINANCIAL/SET_SEARCH_FINANCIAL_SORT',
        payload: {
            siren,
            sortDirection,
            sortSource,
        },
    } as const;
};

export const createFetchFiancialRequestCountAction = (siren: number) => {
    return {
        type: '@FINANCIAL/GET_COUNT',
        payload: {
            siren,
        },
    } as const;
};

export const createFetchFiancialRequestCountSuccessAction = (siren: number, data: FinancialRequestCount) => {
    return {
        type: '@FINANCIAL/GET_COUNT_SUCCESS',
        payload: {
            siren,
            data,
        },
    } as const;
};

export const createFetchFiancialRequestCountFailureAction = (siren: number) => {
    return {
        type: '@FINANCIAL/GET_COUNT_FAILURE',
        payload: {
            siren,
        },
    } as const;
};

export const createFetchRightToSeeFinancialRequestsAction = (siren: number) => {
    return {
        type: '@FINANCIAL/GET_RIGHT_TO_SEE_FINANCIAL_REQUESTS',
        payload: {
            siren,
        },
    } as const;
};

export const createFetchRightToSeeFinancialRequestsSuccessAction = (siren: number, canSeeFinancialRequests: boolean) => {
    return {
        type: '@FINANCIAL/GET_RIGHT_TO_SEE_FINANCIAL_REQUESTS_SUCCESS',
        payload: {
            siren,
            canSeeFinancialRequests,
        },
    } as const;
};

export const createFetchRightToSeeFinancialRequestsFailureAction = (siren: number) => {
    return {
        type: '@FINANCIAL/GET_RIGHT_TO_SEE_FINANCIAL_REQUESTS_FAILURE',
        payload: {
            siren,
        },
    } as const;
};

export type FinancialAction =
    | ReturnType<typeof createSubmitFinancialAction>
    | ReturnType<typeof createSubmitFinancialSuccessAction>
    | ReturnType<typeof createSubmitFinancialFailureAction>
    | ReturnType<typeof createSetFilesAction>
    | ReturnType<typeof createClearFilesAction>
    | ReturnType<typeof createFetchFiancialRequestByIdAction>
    | ReturnType<typeof createFetchFiancialRequestByIdSuccessAction>
    | ReturnType<typeof createFetchFiancialRequestByIdFailureAction>
    | ReturnType<typeof createSearchFinancialRequestAction>
    | ReturnType<typeof createSearchFinancialRequestSuccessAction>
    | ReturnType<typeof createSetSearchFinancialRequestChangePageAction>
    | ReturnType<typeof createSearchFinancialRequestFailureAction>
    | ReturnType<typeof createFetchFiancialRequestCountAction>
    | ReturnType<typeof createFetchFiancialRequestCountSuccessAction>
    | ReturnType<typeof createSetSearchFinancialSortAction>
    | ReturnType<typeof createFetchFiancialRequestCountFailureAction>
    | ReturnType<typeof createFetchRightToSeeFinancialRequestsAction>
    | ReturnType<typeof createFetchRightToSeeFinancialRequestsSuccessAction>
    | ReturnType<typeof createFetchRightToSeeFinancialRequestsFailureAction>;


