import { SearchInvoicesRequest, Invoice } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { ApiRequest, Reducer } from 'store';
import { SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';

export const DEFAULT_SEARCH_INVOICES_TAKE = 10;
export const DEFAULT_SEARCH_INVOICES_FILTERS = {
    skip: 0,
    take: DEFAULT_SEARCH_INVOICES_TAKE,
};

export interface FileInfo { fileSize: number; fileName: string; fileType: string; }

export const initialState = {
    search: {
        filters: {
            ...DEFAULT_SEARCH_INVOICES_FILTERS,
        },
        hasNext: false,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<Invoice[]> & {
        filters: SearchInvoicesRequest,
        hasNext: boolean,
    },
    managementCompany: { isFetching: false } as ApiRequest<string>,
    contractInvoices: {} as { [key: string]: ApiRequest<string[]> & { hasNext?: boolean } },
    invoicesDocument: {
        download: {} as { [key: string]: ApiRequest<FileInfo> },
        bulkDownload: {} as { [key: string]: ApiRequest<FileInfo> },
    },
} as const;

export type InvoicesState = typeof initialState;
export const sirenState: SirenDictionnaryType<InvoicesState> = {};

export const InvoicesReducer: Reducer<SirenDictionnaryType<InvoicesState>> = (state = sirenState, action) => {
    switch (action.type) {

        case '@INVOICES/SET_SEARCH_INVOICES_CONTRACT_ID':
            if (state[action.payload.siren]?.search?.filters?.contractId === action.payload.contractId) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters,
                        contractId: action.payload.contractId,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@INVOICES/SET_SEARCH_INVOICES_BY_INVOICE_NB':
            if (state[action.payload.siren]?.search?.filters?.invoiceNb === action.payload.invoiceNb) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters,
                        invoiceNb: action.payload.invoiceNb,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@INVOICES/SET_SEARCH_INVOICES_END_DATE':
            if (state[action.payload.siren]?.search?.filters?.endDate === action.payload.endDate) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters,
                        endDate: action.payload.endDate,
                        skip: 0,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@INVOICES/SET_SEARCH_MANAGEMENT_COMPANY':
            if (state[action.payload.siren]?.search?.filters?.managementCompany === action.payload.managementCompany) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters,
                        managementCompany: action.payload.managementCompany,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@INVOICES/SET_SEARCH_INVOICES_CHANGE_PAGE':
            if (state[action.payload.siren]?.search?.filters?.skip === action.payload.skip && state[action.payload.siren]?.search?.filters?.take === action.payload.take) {
                return state;
            }
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...state[action.payload.siren]?.search?.filters,
                        skip: action.payload.skip,
                        take: action.payload.take,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@INVOICES/RESET_SEARCH_INVOICES':
            const isAlreadyDefaultFilter = Object.entries(state[action.payload.siren]?.search?.filters).every(([filterKey, filterValue]) => {
                switch (filterKey) {
                    case 'skip':
                        return filterValue === 0;
                    case 'take':
                        return filterValue === DEFAULT_SEARCH_INVOICES_TAKE;
                    default:
                        return !filterValue;
                }
            });

            if (isAlreadyDefaultFilter) {
                return state;
            }

            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    filters: {
                        ...DEFAULT_SEARCH_INVOICES_FILTERS,
                    },
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        case '@INVOICES/SEARCH_INVOICES':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@INVOICES/SEARCH_INVOICES_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.invoices,
                    hasNext: action.payload.hasNext,
                },
            });

        case '@INVOICES/SEARCH_INVOICES_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                search: {
                    ...state[action.payload.siren]?.search,
                    didInvalidate: true,
                    isFetching: false,
                    data: undefined,
                },
            });

        case '@INVOICES/DOWNLOAD_DOCUMENT':
            return sirenStatePatcher(state, action.payload.siren, {
                invoicesDocument: {
                    ...state[action.payload.siren]?.invoicesDocument,
                    download: {
                        ...state[action.payload.siren]?.invoicesDocument?.download,
                        [`${action.payload.invoiceId}-${action.payload.documentId}`]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                    bulkDownload: { ...state[action.payload.siren]?.invoicesDocument?.bulkDownload },
                },
            });

        case '@INVOICES/DOWNLOAD_DOCUMENT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                invoicesDocument: {
                    ...state[action.payload.siren]?.invoicesDocument,
                    download: {
                        ...state[action.payload.siren]?.invoicesDocument?.download,
                        [`${action.payload.invoiceId}-${action.payload.documentId}`]: {
                            didInvalidate: false,
                            isFetching: false,
                            data: {
                                fileSize: action.payload.file.data.size,
                                fileType: action.payload.file.data.type,
                                fileName: action.payload.file.fileName ?? '',
                            },
                        },
                    },
                    bulkDownload: { ...state[action.payload.siren]?.invoicesDocument?.bulkDownload },
                },
            });

        case '@INVOICES/DOWNLOAD_DOCUMENT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                invoicesDocument: {
                    ...state[action.payload.siren]?.invoicesDocument,
                    download: {
                        ...state[action.payload.siren]?.invoicesDocument?.download,
                        [`${action.payload.invoiceId}-${action.payload.documentId}`]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                    bulkDownload: { ...state[action.payload.siren]?.invoicesDocument?.bulkDownload },
                },
            });

        case '@INVOICES/BULK_DOWNLOAD_INVOICE_DOCUMENT':
            return sirenStatePatcher(state, action.payload.siren, {
                invoicesDocument: {
                    ...state[action.payload.siren]?.invoicesDocument,
                    bulkDownload: {
                        ...state[action.payload.siren]?.invoicesDocument?.bulkDownload,
                        [action.payload.key]: {
                            didInvalidate: true,
                            isFetching: true,
                        },
                    },
                    download: { ...state[action.payload.siren]?.invoicesDocument?.download },
                },
            });

        case '@INVOICES/BULK_DOWNLOAD_INVOICE_DOCUMENT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                invoicesDocument: {
                    ...state[action.payload.siren]?.invoicesDocument,
                    bulkDownload: {
                        ...state[action.payload.siren]?.invoicesDocument?.bulkDownload,
                        [action.payload.key]: {
                            didInvalidate: false,
                            isFetching: false,
                            data: {
                                fileSize: action.payload.file.data.size,
                                fileType: action.payload.file.data.type,
                                fileName: action.payload.file.fileName ?? '',
                            },
                        },
                    },
                    download: { ...state[action.payload.siren]?.invoicesDocument?.download },
                },
            });

        case '@INVOICES/BULK_DOWNLOAD_INVOICE_DOCUMENT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                invoicesDocument: {
                    ...state[action.payload.siren]?.invoicesDocument,
                    bulkDownload: {
                        ...state[action.payload.siren]?.invoicesDocument?.bulkDownload,
                        [action.payload.key]: {
                            didInvalidate: true,
                            isFetching: false,
                        },
                    },
                    download: { ...state[action.payload.siren]?.invoicesDocument?.download },
                },
            });

        case '@INVOICES/GET_CONTRACT_INVOICES':
            return sirenStatePatcher(state, action.payload.siren, {
                contractInvoices: {
                    ...state[action.payload.siren]?.contractInvoices,
                    [action.payload.key]: {
                        didInvalidate: true,
                        isFetching: true,
                    },
                },
            });

        case '@INVOICES/GET_CONTRACT_INVOICES_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                contractInvoices: {
                    ...state[action.payload.siren]?.contractInvoices,
                    [action.payload.key]: {
                        didInvalidate: false,
                        isFetching: false,
                        hasNext: action.payload.hasNext,
                        data: action.payload.invoiceIds,
                    },
                },
            });

        case '@INVOICES/GET_CONTRACT_INVOICES_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractInvoices: {
                    ...state[action.payload.siren]?.contractInvoices,
                    [action.payload.key]: {
                        didInvalidate: true,
                        isFetching: false,
                    },
                },
            });

        case '@INVOICES/GET_MANAGEMENT_COMPANY':
            return sirenStatePatcher(state, action.payload.siren, {
                managementCompany: {
                    didInvalidate: true,
                    isFetching: true,
                },
            });

        case '@INVOICES/GET_MANAGEMENT_COMPANY_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                managementCompany: {
                    didInvalidate: false,
                    isFetching: false,
                    data: action.payload.managementCompany,
                },
            });

        case '@INVOICES/GET_MANAGEMENT_COMPANY_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                managementCompany: {
                    didInvalidate: true,
                    isFetching: false,
                },
            });

        default:
            return state;
    }
};
