import { TypeOfBuyback } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractTerminationOfContractInitialState = {
    contractIds: [] as string[],
    typeofbuyback: undefined as TypeOfBuyback | undefined,
    materials: undefined as string[] | undefined,
    redemptionDate: undefined as Date | undefined,
    reason: undefined as string | undefined,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type EndOfContractTerminationOfContractState = typeof endOfContractTerminationOfContractInitialState;
export const sirenEndOfContractTerminationOfContractInitialState: SirenDictionnaryType<EndOfContractTerminationOfContractState> = {};

export const EndOfContractTerminationOfContractReducer: Reducer<SirenDictionnaryType<EndOfContractTerminationOfContractState>> = (state = sirenEndOfContractTerminationOfContractInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/SET_FORM_VALUE':

            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                typeofbuyback: action.payload.typeofbuyback,
                redemptionDate: action.payload.redemptionDate,
                materials: action.payload.materials,
                reason: action.payload.reason,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...endOfContractTerminationOfContractInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/TERMINATION_OF_CONTRACT/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
