import { normalize } from 'normalizr';
import { ThunkAction } from 'components/common/AppProvider';
import { AppContextsApi, FranfinanceApiSchema } from 'services/ApiService';
import { AppContext } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergeFranfinanceEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchAppContextAction,
    createFetchAppContextSuccessAction,
    createFetchAppContextFailureAction,
    createSetClientAction,
} from './AppContextActions';

export const getAppContext = (): ThunkAction<Promise<AppContext>> => async (dispatch, getState) => {
    const state = getState();
    if (state.appContext.isFetching || state.appContext.environment) {
        const promise = PromiseStore.get<AppContext>('getAppContext');
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await AppContextsApi.get();
            let loggedUserId: string | undefined;
            if (data.loggedUser) {
                const normalizedData = normalize(data.loggedUser, FranfinanceApiSchema.LoggedUserSchema);
                dispatch(mergeFranfinanceEntities(normalizedData.entities));
                loggedUserId = normalizedData.result;
            }
            dispatch(createFetchAppContextSuccessAction(data.environment, loggedUserId));
            data.loggedUser?.clientName && data.loggedUser?.siren && dispatch(createSetClientAction(data.loggedUser?.clientName, data.loggedUser?.siren));
            return data;
        })();

        PromiseStore.set(fetchTask, 'getAppContext');

        dispatch(createFetchAppContextAction());

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchAppContextFailureAction());
        logError(error);
        throw error;
    }
};
