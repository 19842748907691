import { SgConnectEnvironment } from 'services/SgConnect';

export enum Environment {
    Local = 'Local',
    Development = 'Development',
    Homologation = 'Homologation',
    Production = 'Production',
    Test = 'Test',
}

export const getEnvironment = () => {
    let environment = Environment.Production;

    if (typeof window === 'undefined') {
        environment = Environment.Test;
    } else if (window.location.hostname === 'local.fr.world.socgen'
        || window.location.hostname === 'localhost') {
        environment = Environment.Local;
    } else if (window.location.hostname === 'entreprisesfranfinance-dev.sgmarkets.com') {
        environment = Environment.Development;
    } else if (window.location.hostname === 'entreprisesfranfinance-uat.sgmarkets.com') {
        environment = Environment.Homologation;
    } else if (window.location.hostname === 'entreprisesfranfinance.sgmarkets.com'
        || window.location.hostname === 'entreprisesfranfinance-preview.sgmarkets.com') {
        environment = Environment.Production;
    }
    return environment;
};

interface IConfiguration {
    baseApiUrls: {
        franfinance: string;
    };
    externalLinks: {
        franfinanceLegalNotice: string;
        franfinanceMentionsLegales: string;
        franfinanceCgu: string;
        franfinanceCookies: string;
        franfinanceWebsite: string;
        requestAccessUrl: (returnUrl?: string, sourceUrl?: string) => string;
        rgpd: string;
        ManagementCompany: { [key: string]: string },
        referencingLinks: { [key: string]: { [key: string]: string } },
    },
    matomoSiteId?: string;
    authorizationEndpoint: string;
    clientId: string;
    scope: string;
    sgConnectEnvironment: SgConnectEnvironment;
    widgetCdnBaseUrl: string;
    sgMonitoringRumUrl: string;
    simulation: {
        defaultDurations: number[];
        minDuration: number;
        maxDuration: number;
        minAmount: number;
        maxAmount: number;
    };
    helpCenter: {
        topics: {
            equipmentRelatedForm: {
                rentalCertificate: string;
                endOfRentalCertificate: string;
            };
            customerRequests: {
                contractTransfert: string;
                financialCondition: string;
            };
        };
    };
    disableCustomerRequestResponse: boolean;
}

const defaultConfiguration = {
    baseApiUrls: {
        franfinance: 'https://franfinance-api-enterprise-uat.sgmpinsight.dev.euw.gbis.sg-azure.com',
    },
    externalLinks: {
        franfinanceWebsite: 'https://franfinance.com',
        requestAccessUrl: (returnUrl = window.location.href, sourceUrl = window.location.origin) =>
            `https://info-uat.sgmarkets.com/fr/request-access?returnurl=${returnUrl}&sourceurl=${sourceUrl}&sourcecontext=Franfinance`,
        rgpd: 'https://global.societegenerale.com/fileadmin/user_upload/GDPR/data-policy/france/en/20180627_Politique_de_Protection_de_Donnees_Societe_Generale_CIB_SGSS_GTPS_VF.PDF',
        franfinanceLegalNotice: 'https://content.sgmarkets.com/FRANFINANCE-LEGAL-NOTICE',
        franfinanceMentionsLegales: 'https://content.sgmarkets.com/FRANFINANCE-MENTIONS-LEGALES',
        franfinanceCgu: 'https://content.sgmarkets.com/FRANFINANCE-CGU',
        franfinanceCookies: 'https://content.sgmarkets.com/FRANFINANCE-COOKIES',
        ManagementCompany: {
            FFINAN: 'https://franfinance.e-facture.net',
            FFLOC: 'https://franfinance-loc.e-facture.net',
            FENWICK: 'https://fenwicklease.e-facture.net',
        },
        referencingLinks: {
            FFINAN: {
                AttestationAssuranceRcp: 'https://content.sgmarkets.com/FRANFINANCE-ATTESTATION-ASSURANCE-RCP',
                AttestationRegulariteFiscale: 'https://content.sgmarkets.com/FRANFINANCE-ATTESTATION-REGULARITE-FISCALE',
                AttestationVigilanceUrsaf: 'https://content.sgmarkets.com/FRANFINANCE-ATTESTATION-VIGILANCE-URSSAF',
                Kbis: 'https://content.sgmarkets.com/FRANFINANCE-KBIS',
                Rib: 'https://content.sgmarkets.com/FRANFINANCE-RIB',
            },
            FFLOC: {
                AttestationAssuranceRcp: 'https://content.sgmarkets.com/FRANFINANCE-LOCATION-ATTESTATION-ASSURANCE-RCP',
                AttestationAbsenceDeSalarie: 'https://content.sgmarkets.com/FRANFINANCE-LOCATION-ATTESTATION-ABSENCE-DE-SALARIE',
                AttestationRegulariteFiscale: 'https://content.sgmarkets.com/FRANFINANCE-LOCATION-ATTESTATION-REGULARITE-FISCALE',
                Kbis: 'https://content.sgmarkets.com/FRANFINANCE-LOCATION-KBIS',
                Rib: 'https://content.sgmarkets.com/FRANFINANCE-LOCATION-RIB',
            },
            FENWICK: {
                AttestationAssuranceRcp: 'https://content.sgmarkets.com/FENWICK-ATTESTATION-ASSURANCE-RCP',
                AttestationAbsenceDeSalarie: 'https://content.sgmarkets.com/FENWICK-ATTESTATION-ABSENCE-DE-SALARIE',
                AttestationRegulariteFiscale: 'https://content.sgmarkets.com/FENWICK-ATTESTATION-REGULARITE-FISCALE',
                Kbis: 'https://content.sgmarkets.com/FENWICK-KBIS',
                Rib: 'https://content.sgmarkets.com/FENWICK-RIB',
            },
        },
    },
    matomoSiteId: '877541172',
    clientId: '660b723a-ceee-4dc6-a38b-922d5b349581',
    authorizationEndpoint: 'https://sgconnect-hom.fr.world.socgen/sgconnect',
    scope: [
        'mail',
        'openid',
        'profile',
        'api.franfinance-enterprise.v1',
        'api.franfinance-enterprise.admin.v1',
    ].join(' '),
    sgConnectEnvironment: SgConnectEnvironment.Homologation,
    widgetCdnBaseUrl: 'https://sgwt-widgets-dev.sgmarkets.com',
    sgMonitoringRumUrl: 'https://t-monitoring-rum-uat.sgmarkets.com/rum/817315b0bcda4502ab8ff5d574eb0d32',
    simulation: {
        defaultDurations: [36, 48, 60],
        minDuration: 24,
        maxDuration: 84,
        minAmount: 500,
        maxAmount: 149999,
    },
    helpCenter: {
        topics: {
            equipmentRelatedForm: {
                rentalCertificate: '15h862mr',
                endOfRentalCertificate: '40iku017',
            },
            customerRequests: {
                contractTransfert: 'bwxtjrap',
                financialCondition: 'xvqt7fj7',
            },
        },
    },
    disableCustomerRequestResponse: false,
};

export const configurationPerEnvironment: Record<Environment, IConfiguration> = {
    [Environment.Local]: {
        ...defaultConfiguration,
    },
    [Environment.Development]: {
        ...defaultConfiguration,
        matomoSiteId: '877541172',
        baseApiUrls: {
            franfinance: 'https://franfinance-api-enterprise-dev.sgmpinsight.dev.euw.gbis.sg-azure.com',
        },
    },
    [Environment.Homologation]: {
        ...defaultConfiguration,
        matomoSiteId: '877541173',
        baseApiUrls: {
            franfinance: 'https://api-z-dev.sgmarkets.com/uat/services/franfinance/private/v1',
        },
    },
    [Environment.Production]: {
        ...defaultConfiguration,
        baseApiUrls: {
            franfinance: 'https://api-z.sgmarkets.com/services/franfinance-enterprise/private/v1',
        },
        externalLinks: {
            ...defaultConfiguration.externalLinks,
            requestAccessUrl: (returnUrl = window.location.origin, sourceUrl = window.location.hostname) =>
                `https://info.sgmarkets.com/fr/request-access?returnurl=${returnUrl}&sourceurl=${sourceUrl}&sourcecontext=Franfinance`,
        },
        matomoSiteId: '1457',
        clientId: '9cd0d0f8-1523-412b-b475-3a4c12bc37d2',
        authorizationEndpoint: 'https://sso.sgmarkets.com/sgconnect/oauth2/authorize',
        sgConnectEnvironment: SgConnectEnvironment.Production,
        widgetCdnBaseUrl: 'https://sgwt-cdn-widgets.sgmarkets.com',
        sgMonitoringRumUrl: 'https://t-monitoring-rum.sgmarkets.com/rum/540a239031b64d7fbe34fe54fef041db',
        helpCenter: {
            topics: {
                equipmentRelatedForm: {
                    rentalCertificate: '2219j2yq',
                    endOfRentalCertificate: '2c7ulq8b',
                },
                customerRequests: {
                    contractTransfert: 'bhrnq43y',
                    financialCondition: 'nex2bo9r',
                },
            },
        },
        disableCustomerRequestResponse: true,
    },
    [Environment.Test]: {
        ...defaultConfiguration,
    },
};

export const configuration = configurationPerEnvironment[getEnvironment()];
