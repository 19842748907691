import { ThunkAction } from 'components/common/AppProvider';
import { normalize } from 'normalizr';
import { PersonsApi, FranfinanceApiSchema } from 'services/ApiService';
import { Person } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { abortRequests } from 'services/ApiService/Http';
import { logError } from 'services/Logger';
import { PromiseStore } from 'services/PromiseStore';
import { mergeFranfinanceEntities } from 'store/Normalizr/NormalizrAction';
import {
    createFetchNavigateAsPersonsAction,
    createFetchNavigateAsPersonsFailureAction,
    createFetchNavigateAsPersonsSuccessAction,
    createFetchPersonAction,
    createFetchPersonFailureAction,
    createFetchPersonSuccessAction,
} from './PersonsActions';

export const searchNavigateAs = (): ThunkAction<Promise<Person[]>> => async (dispatch, getState) => {
    const state = getState();

    if (!state.persons.navigateAs.didInvalidate || state.persons.navigateAs.isFetching) {
        const promise = PromiseStore.get<Person[]>('searchNavigateAs');
        if (promise) {
            return promise;
        }
    }

    try {
        abortRequests('/api/v1/persons/navigate-as', 'GET');

        const { searchTerms } = state.persons.navigateAs;

        if (!searchTerms?.trim()) {
            dispatch(createFetchNavigateAsPersonsSuccessAction([]));
            return [];
        }

        const fetchTask = (async () => {
            const data = await PersonsApi.getNavigateAsPersons(searchTerms || '');
            const normalizedData = normalize(data.persons, FranfinanceApiSchema.PersonSchemaArray);
            dispatch(mergeFranfinanceEntities(normalizedData.entities));
            dispatch(createFetchNavigateAsPersonsSuccessAction(normalizedData.result));
            return data.persons || [];
        })();

        PromiseStore.set(fetchTask, 'searchNavigateAs');

        dispatch(createFetchNavigateAsPersonsAction());

        return await fetchTask;
    }
    catch (error) {
        if ((error as Error)?.name !== 'AbortError') {
            dispatch(createFetchNavigateAsPersonsFailureAction());
            logError(error);
        }
        throw error;
    }
};

export const getPerson = (icId: string): ThunkAction<Promise<Person>> => async (dispatch, getState) => {
    const state = getState();
    const request = state.persons.persons[icId];
    if (request?.isFetching || !request?.didInvalidate) {
        const promise = PromiseStore.get<Person>('getPerson', icId);
        if (promise) {
            return await promise;
        }
    }

    try {
        const fetchTask = (async () => {
            const data = await PersonsApi.getPerson(icId);
            const normalizedData = normalize(data, FranfinanceApiSchema.PersonSchema);
            dispatch(mergeFranfinanceEntities(normalizedData.entities));
            dispatch(createFetchPersonSuccessAction(icId));

            return data;
        })();

        PromiseStore.set(fetchTask, 'getPerson', icId);

        dispatch(createFetchPersonAction(icId));

        return await fetchTask;
    }
    catch (error) {
        dispatch(createFetchPersonFailureAction(icId));
        logError(error);
        throw error;
    }
};
