import { SubscribeCode } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { ApiRequest, Reducer } from 'store';
import { SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';

export const initialState = {
    subscribeCode: {} as ApiRequest<SubscribeCode>,
} as const;

export type SubscribeCodeState = typeof initialState;

export const sirenState: SirenDictionnaryType<SubscribeCodeState> = {};

export const SubscribeCodeReducer: Reducer<SirenDictionnaryType<SubscribeCodeState>> = (state = sirenState, action) => {
    switch (action.type) {
        case '@SUBSCRIBE_CODE/GET_CODE':
            return sirenStatePatcher(state, action.payload.siren, {
                subscribeCode: {
                    isFetching: true,
                    didInvalidate: true,
                },
            });

        case '@SUBSCRIBE_CODE/GET_CODE_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                subscribeCode: {
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.data,
                },
            });

        case '@SUBSCRIBE_CODE/GET_CODE_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                subscribeCode: {
                    isFetching: false,
                    didInvalidate: true,
                },
            });

        default:
            return state;
    }
};
