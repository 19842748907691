declare global {
    // tslint:disable-next-line:interface-name
    interface Element {
        trackEvent(category: string, action: string, name?: string, value?: string): void;
    }
}

const getAnalytics = () => {
    return new Promise<Element | null>((resolve) => {
        const webAnalytics = document.querySelector('sgwt-web-analytics');
        if (webAnalytics) {
            resolve(webAnalytics);
        }
        const getwebAnalytics = () => {
            document.removeEventListener('sgwt-web-analytics--ready', getwebAnalytics);
            resolve(document.querySelector('sgwt-web-analytics'));
        };
        document.addEventListener('sgwt-web-analytics--ready', getwebAnalytics);
    });
};

export const trackEvent = async (category: string, action: string, name?: string, value?: string) => {
    const webAnalytics = await getAnalytics();
    webAnalytics?.trackEvent(category, action, name, value);
};
