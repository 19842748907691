import { Address } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const billingBillingAddressInitialState = {
    contractIds: [] as string[],
    billingAddress: {
        streetNumber: '',
        streetName: '',
        zipCode: undefined as number | undefined,
        city: '',
    } as Address,
    deliveryAddress: undefined as Address | undefined,
    hasDeliveryAddress: false as boolean,
    termsAccepted: false as boolean,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
} as const;

type BillingBillingAddressState = typeof billingBillingAddressInitialState;
export const sirenBillingBillingAddressInitialState: SirenDictionnaryType<BillingBillingAddressState> = {};

export const BillingBillingAddressReducer: Reducer<SirenDictionnaryType<BillingBillingAddressState>> = (state = sirenBillingBillingAddressInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                billingAddress: { ...action.payload.billingAddress },
                deliveryAddress: action.payload.deliveryAddress !== undefined ? { ...action.payload.deliveryAddress } : undefined,
                hasDeliveryAddress: action.payload.hasDeliveryAddress,
                termsAccepted: action.payload.termsAccepted,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },

            });
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...billingBillingAddressInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/BILLING/BILLING_ADDRESS/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
