import { PromiseStore } from 'services/PromiseStore';

export const loadScript = (id: string, src: string, async = true) => {
    let scriptLoadingTask = PromiseStore.get('loadScript', id);
    const existingScript = document.getElementById(id);
    if (existingScript && scriptLoadingTask) {
        return scriptLoadingTask;
    }

    scriptLoadingTask = new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = async;
        script.id = id;
        script.onload = () => {
            resolve();
        };
        script.onerror = (err) => {
            reject(err);
        };
        script.src = src;
        document.body.appendChild(script);
    });

    PromiseStore.set(scriptLoadingTask, 'loadScript', id);

    return scriptLoadingTask;
};
