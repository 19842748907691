import { EquipmentRelatedDocument } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentEquipmentRelatedStateInitialState = {
    contractIds: [] as string[],
    start: undefined as Date | undefined,
    end: undefined as Date | undefined,
    documents: [] as EquipmentRelatedDocument[],
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type DocumentEquipmentRelatedState = typeof documentEquipmentRelatedStateInitialState;
export const sirenDocumentEquipmentRelatedStateInitialState: SirenDictionnaryType<DocumentEquipmentRelatedState> = {};

export const DocumentEquipmentRelatedReducer: Reducer<SirenDictionnaryType<DocumentEquipmentRelatedState>> = (state = sirenDocumentEquipmentRelatedStateInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                start: action.payload.start,
                end: action.payload.end,
                documents: action.payload.documents,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },
            });
        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...documentEquipmentRelatedStateInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/DOCUMENT/EQUIPMENT_RELATED/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};