import { FileAndType } from 'services/FileService';
import { Reducer, SirenDictionnaryType, sirenStatePatcher } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const endOfContractExerciseOfPurchaseOptionInitialState = {
    contractIds: [] as string[],
    termsAccepted: false as boolean,
    request: {
        isFetching: false,
    } as ApiRequest<string[]>,
    files: [] as FileAndType[],
    optionalCommentary: '' as string,
} as const;

type EndOfContractExerciseOfPurchaseOptionState = typeof endOfContractExerciseOfPurchaseOptionInitialState;
export const sirenEndOfContractExerciseOfPurchaseOptionInitialState: SirenDictionnaryType<EndOfContractExerciseOfPurchaseOptionState> = {};

export const EndOfContractExerciseOfPurchaseOptionReducer: Reducer<SirenDictionnaryType<EndOfContractExerciseOfPurchaseOptionState>> = (state = sirenEndOfContractExerciseOfPurchaseOptionInitialState, action) => {
    switch (action.type) {
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EXERCISE_OF_PURCHASE_OPTION/SET_FORM_VALUE':
            return sirenStatePatcher(state, action.payload.siren, {
                contractIds: action.payload.contractIds,
                termsAccepted: action.payload.termsAccepted,
                files: action.payload.files,
                optionalCommentary: action.payload.optionalCommentary,
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EXERCISE_OF_PURCHASE_OPTION/SUBMIT':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: true,
                },

            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EXERCISE_OF_PURCHASE_OPTION/SUBMIT_SUCCESS':
            return sirenStatePatcher(state, action.payload.siren, {
                ...endOfContractExerciseOfPurchaseOptionInitialState,
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        case '@CUSTOMER_SERVICE/END_OF_CONTRACT/EXERCISE_OF_PURCHASE_OPTION/SUBMIT_FAILURE':
            return sirenStatePatcher(state, action.payload.siren, {
                request: {
                    ...state[action.payload.siren]?.request,
                    isFetching: false,
                },
            });
        default:
            return state;
    }
};
