import { ApiRequest, Reducer } from 'store';

export interface IPersonRequestMap {
    readonly [icId: string]: ApiRequest;
}

export const initialState = {
    navigateAs: {
        searchTerms: undefined,
        data: undefined,
        isFetching: false,
        didInvalidate: true,
    } as ApiRequest<string[]> & { searchTerms?: string | null },
    persons: {} as { readonly [icId: string]: ApiRequest },
} as const;

export type PersonsState = typeof initialState;

export const PersonsReducer: Reducer<PersonsState> = (state = initialState, action) => {
    switch (action.type) {
        case '@PERSONS/SET_NAVIGATE_AS_SEARCH_TERMS':
            const searchTermsHaveChanged = state.navigateAs.searchTerms !== action.payload.searchTerms;
            if (!searchTermsHaveChanged) {
                return state;
            }
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    searchTerms: action.payload.searchTerms,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: true,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_SUCCESS':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: false,
                    didInvalidate: false,
                    data: action.payload.icIds,
                },
            };

        case '@PERSONS/FETCH_NAVIGATE_AS_PERSONS_FAILURE':
            return {
                ...state,
                navigateAs: {
                    ...state.navigateAs,
                    isFetching: false,
                    didInvalidate: true,
                },
            };

        case '@PERSONS/FETCH_PERSON':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    [action.payload.icId]: {
                        ...state.persons[action.payload.icId],
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };

        case '@PERSONS/FETCH_PERSON_SUCCESS':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    [action.payload.icId]: {
                        ...state.persons[action.payload.icId],
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };

        case '@PERSONS/FETCH_PERSON_FAILURE':
            return {
                ...state,
                persons: {
                    ...state.persons,
                    [action.payload.icId]: {
                        ...state.persons[action.payload.icId],
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };

        default:
            return state;
    }
};
