import { PresentationCard } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store';

export const initialState = {
    presentationCards: [] as PresentationCard[],
    isFetching: false as boolean,
    didInvalidate: true as boolean,
} as const;

export type HomeState = typeof initialState;

export const HomeReducer: Reducer<HomeState> = (state = initialState, action) => {
    switch (action.type) {
        case '@HOME/FETCH_PRESENTATION_CARD':
            return {
                ...state,
                didInvalidate: true,
                isFetching: true,
            };

        case '@HOME/FETCH_PRESENTATION_CARD_SUCCESS':
            return {
                ...state,
                didInvalidate: false,
                isFetching: false,
                presentationCards: action.payload.cards,
            };

        case '@HOME/FETCH_PRESENTATION_CARD_FAILURE':
            return {
                ...state,
                didInvalidate: true,
                isFetching: false,
            };

        default:
            return state;
    }
};
