import { DocumentEquipmentRelatedDetails } from 'services/ApiService/Franfinance/FranfinanceApiClient';
import { Reducer } from 'store/configureStore';
import { ApiRequest } from 'store/sharedModels';

export const documentEquipmentRelatedInitialState = {
    results: {} as { [id: string]: ApiRequest<DocumentEquipmentRelatedDetails> },
} as const;

type DocumentEquipmentRelatedState = typeof documentEquipmentRelatedInitialState;

export const DocumentEquipmentRelatedReducer: Reducer<DocumentEquipmentRelatedState> = (state = documentEquipmentRelatedInitialState, action) => {
    switch (action.type) {

        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/EQUIPMENT_RELATED/GET':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: true,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/EQUIPMENT_RELATED/GET_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: false,
                    },
                },
            };
        case '@CUSTOMER_REQUEST_DETAILS/DOCUMENT/EQUIPMENT_RELATED/GET_FAILURE':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/INSTRUCTION/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        case '@CUSTOMER_SERVICE_DETAILS_RESPONSE/NON_COMPLIANCE/SUBMIT_SUCCESS':
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.payload.caseId]: {
                        isFetching: false,
                        didInvalidate: true,
                    },
                },
            };
        default:
            return state;

    }
};